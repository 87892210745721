import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, InputNumber, message, Modal, Row, Select, Spin } from "antd";
import { fi } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { CompactPicker } from "react-color";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Api } from "../../api";
import { useItemOptionsList } from "../../api/useItemOptionsList";
import { useSubmitForm } from "../../helpers/forms";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";
import FileUploadV2 from "../fileUploadV2/FileUploadV2";
import PriceInput from "../PriceInput";
import ItemOptionPicker from "./ItemOptionPicker";

const ItemVariationEditModal: React.FC<{
  variationId?: number;
  itemId?: number;
  onChange?: () => void;
}> = ({ variationId, itemId, children, onChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optionsList, loadingItemOptions] = useItemOptionsList();
  const { handleSubmit, errors, control, reset, watch, setValue, register } = useForm({
    defaultValues: { item: itemId },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "options", // unique name for your Field Array

    // keyName: "id", default to "id", you can change the key name
  });

  const showModal = () => {
    setIsModalVisible(true);
  };
  // console.log(itemId);
  // useEffect(() => {
  //     register('options', { required: true, minLength: 1 })
  // }, [])
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // const submit = (data: any) => {
  //     console.log(data);
  // }

  const [notFound, loading, data] = useLoadFormData({
    apiCall: Api.itemVariation.single,
    id: variationId ? variationId + "" : undefined,
    setValue: reset,
    imageFields: [],
  });
  const fieldValue: any = useWatch({ control, name: "options" });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.itemVariation.create,
    updateApiCall: Api.itemVariation.update,
    onSuccess: () => {
      message.success("Variation Saved");
      onChange && onChange();
      setIsModalVisible(false);
    },
    imageFields: [],
    updateId: variationId ? variationId + "" : undefined,
  });

  return (
    <>
      <div onClick={showModal}>{children}</div>

      <Modal
        width={"60%"}
        onCancel={handleCancel}
        title="Create/Edit Variation"
        visible={isModalVisible}
        footer={null}
      >
        <form className="white-container" onSubmit={handleSubmit(submit)}>
          <Row gutter={4} align="middle">
            <Controller
              as={<Input hidden />}
              name={`item`}
              defaultValue={itemId || undefined}
              control={control}
            />
            <Col span={6}>
              <label style={{ fontSize: "0.8rem" }}>SKU</label>
              <Controller
                as={<Input placeholder="SKU (Stock Keeping Unit)" />}
                name={`sku`}
                control={control}
              />
              <FieldErrorMessage errors={errors} name={`sku`} />
            </Col>
            <Col span={6}>
              <label style={{ fontSize: "0.8rem" }}>Weight </label>
              <Controller
                as={<Input placeholder="Weight" />}
                name={`shippingInfo.weight`}
                control={control}
              />
              <FieldErrorMessage errors={errors} name={`shippingInfo.weight`} />
            </Col>
            <Col span={24}>
              <label htmlFor={"images"}>Images</label>
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <FileUploadV2 onChange={onChange} value={value} limit={5} />
                )}
                name={`images`}
              />
              <FieldErrorMessage errors={errors} name={`image`} />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: "0.8rem" }}>Price</label>
              <Controller
                as={<PriceInput style={{ display: "block", width: "100%" }} placeholder="Price" />}
                name={`pricing.retail`}
                rules={{ required: true }}
                control={control}
              />
              <FieldErrorMessage errors={errors} name={`pricing.retail`} />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: "0.8rem" }}>Compare At Price</label>
              <Controller
                as={
                  <PriceInput
                    style={{ display: "block", width: "100%" }}
                    placeholder="Compare At Price"
                  />
                }
                name={`pricing.compareAtPrice`}
                rules={{ required: false }}
                control={control}
              />
              <FieldErrorMessage errors={errors} name={`pricing.compareAtPrice`} />
            </Col>
            <Col span={4}>
              <label style={{ fontSize: "0.8rem" }}>Percentage Off</label>
              <Controller
                as={
                  <InputNumber
                    min={1}
                    max={100}
                    style={{ display: "block", width: "100%" }}
                    placeholder="% Off"
                  />
                }
                name={`pricing.percentageOff`}
                rules={{ required: false }}
                control={control}
              />
              <FieldErrorMessage errors={errors} name={`pricing.percentageOff`} />
            </Col>
            <Col span={6}>
              <label style={{ fontSize: "0.8rem" }}>Quantity</label>
              <Controller
                as={
                  <InputNumber style={{ display: "block", width: "100%" }} placeholder="Quantity" />
                }
                name={`quantity`}
                rules={{ required: true }}
                control={control}
              />
              <FieldErrorMessage errors={errors} name={`quantity`} />
            </Col>
            <Col span={24}>
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <Divider />
                  <Row key={field.id} gutter={12}>
                    <Col span={12}>
                      <label style={{ fontSize: "0.8rem" }}>Option</label>
                      <Controller
                        as={
                          <ItemOptionPicker
                            selectedOptions={
                              fieldValue
                                ? fieldValue
                                    .filter((i: any, ind: any) => ind !== index)
                                    .map((f: any) => {
                                      return f.option;
                                    })
                                : []
                            }
                          />
                        }
                        name={`options[${index}].option`}
                        defaultValue={field.option} // make sure to set up defaultValue
                        control={control}
                        rules={{ required: "Option required" }}
                      />
                      <FieldErrorMessage errors={errors} name={`options[${index}].option`} />
                    </Col>
                    <Col span={12}>
                      <label style={{ fontSize: "0.8rem" }}>Values</label>
                      <Controller
                        as={<Input style={{ width: "100%" }} placeholder="Values" />}
                        name={`options[${index}].value`}
                        defaultValue={field.values} // make sure to set up defaultValue
                        control={control}
                        rules={{ required: "Values required" }}
                      />
                      <FieldErrorMessage errors={errors} name={`options[${index}].value`} />
                    </Col>
                    {optionsList.find((opt) => opt._id === field.option)?.type === "color" && (
                      <>
                        <Col span={12}>
                          <label style={{ fontSize: "0.8rem", display: "block" }}>Color</label>
                          <Controller
                            control={control}
                            name={`options[${index}].meta.hex`}
                            defaultValue={field.meta?.hex} // make sure to set up defaultValue
                            render={({ onChange, value }) => (
                              <CompactPicker
                                onChangeComplete={(color) => onChange(color?.hex)}
                                color={value}
                              />
                            )}
                          />
                          <FieldErrorMessage errors={errors} name={`options[${index}].meta.hex`} />
                        </Col>
                        <Col span={6}>
                          <label style={{ fontSize: "0.8rem" }}>Material Image</label>
                          <Controller
                            control={control}
                            defaultValue={field.meta?.materialImage}
                            as={<FileUploadV2 singleValue limit={1} />}
                            // rules={{
                            //     validate: ImagesHelpers.validate
                            // }}
                            name={`options[${index}].meta.materialImage`}
                          />
                          <FieldErrorMessage
                            errors={errors}
                            name={`options[${index}].meta.materialImage`}
                          />
                        </Col>
                      </>
                    )}

                    {
                      <Col style={{ marginTop: "auto" }} span={2}>
                        <Button
                          onClick={() => {
                            remove(index);
                          }}
                          icon={<DeleteOutlined />}
                        >
                          Delete Option
                        </Button>
                      </Col>
                    }
                  </Row>
                </React.Fragment>
              ))}
              <Button
                style={{ marginTop: 10 }}
                icon={<PlusOutlined />}
                onClick={() => {
                  append({});
                }}
              >
                Add Option
              </Button>
              <FieldErrorMessage errors={errors} name={`options`} />
            </Col>
          </Row>
          {
            <Button
              disabled={loading || loadingSubmit}
              loading={loading || loadingSubmit}
              style={{ marginTop: 10 }}
              htmlType="submit"
            >
              Submit
            </Button>
          }
        </form>
      </Modal>
    </>
  );
};

export default ItemVariationEditModal;
